/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import classNames from 'classnames/bind';
import { Button } from '../../atoms';
import type { PatientRequest } from '../../../types';
import style from './AppointmentItems.module.scss';

const cx = classNames.bind(style);

const TextLabel = cx('TextLabel');
const TextBold = cx('TextBold');
const RowContainer = cx({ AppointmentItemsRowContainer: true });
const ColumnContainer = cx({ AppointmentItemsColumnContainer: true });
const RoundedButton = cx({ AppointmentItemsRoundedButton: true });

interface RequestHeaderProps {
  request: PatientRequest;
  open?: boolean;
  onClick?: () => void;
}

export const RequestHeader = ({ request, open, onClick }: RequestHeaderProps) => (
  <div className={RowContainer}>
    <div className={ColumnContainer}>
      <span className={TextLabel}>Datum</span>
      <span className={TextBold}>
        {new Date(request.requestDate).toLocaleString('de-DE', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        })}
      </span>
    </div>
    <div className={ColumnContainer}>
      <span className={TextLabel}>Uhrzeit</span>
      <span className={TextBold}>
        {new Date(request.requestDate).toLocaleString('de-DE', {
          hour: '2-digit',
          minute: '2-digit',
        })}
      </span>
    </div>
    <div className={ColumnContainer}>
      <span className={TextLabel}>Typ</span>
      <span className={TextBold}>Folgerezept</span>
    </div>
    {request.status ? (
      <div className={ColumnContainer}>
        <span className={TextLabel}>Status</span>
        {request.status === 2 && <span className={TextBold}>akzeptiert</span>}
        {request.status === 3 && <span className={TextBold}>declined</span>}
      </div>
    ) : (
      <div className={ColumnContainer} />
    )}
    {open && (
      <div className={ColumnContainer}>
        <Button children={'Antrag bearbeiten'} className={RoundedButton} type={'primary'} onClick={onClick} />
      </div>
    )}
  </div>
);
