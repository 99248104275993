import React, { useState, ReactNode, CSSProperties } from 'react';

interface TooltipProps {
  content: string | JSX.Element;
  children: ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
}

const Tooltip: React.FC<TooltipProps> = ({ content, children, placement = 'top' }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  const tooltipStyle: CSSProperties = {
    position: 'absolute',
    backgroundColor: '#333',
    color: 'white',
    padding: '8px',
    borderRadius: '4px',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    visibility: isVisible ? 'visible' : 'hidden',
    opacity: isVisible ? 1 : 0,
    transition: 'opacity 0.3s',
    zIndex: 1,
  };

  let placementStyle: CSSProperties = {};

  switch (placement) {
    case 'top':
      placementStyle = { bottom: '100%', left: '50%', transform: 'translateX(-50%)', marginBottom: '5px' };
      break;
    case 'bottom':
      placementStyle = { top: '100%', left: '50%', transform: 'translateX(-50%)', marginTop: '5px' };
      break;
    case 'left':
      placementStyle = { right: '100%', top: '50%', transform: 'translateY(-50%)', marginRight: '5px' };
      break;
    case 'right':
      placementStyle = { left: '100%', top: '50%', transform: 'translateY(-50%)', marginLeft: '5px' };
      break;
    default:
      placementStyle = { bottom: '100%', left: '50%', transform: 'translateX(-50%)', marginBottom: '5px' };
  }

  return (
    <div
      style={{ position: 'relative', display: 'inline-block' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      <div style={{ ...tooltipStyle, ...placementStyle }}>{content}</div>
    </div>
  );
};

export default Tooltip;
