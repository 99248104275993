/* eslint-disable import/prefer-default-export */
import { Typography } from '@mui/material';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';
import { Icon, Text } from '../../components/atoms';
import style from './PatientsHeader.module.scss';

const cx = classNames.bind(style);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function PatientsHeader({ activePatientsNumber, patientsNumber }: any) {
  const DivClasses = cx({ PatientsHeaderDiv: true });
  const TitleDivClasses = cx({ PatientsHeaderTitleDiv: true });
  const TitleTextClasses = cx({ PatientsHeaderTitleText: true });
  const TextDivClasses = cx({ PatientsHeaderTextDiv: true });
  const TextClasses = cx({ PatientsHeaderText: true });

  return (
    <div className={DivClasses}>
      <Icon icon='account-plus' size={70} />
      <div className={TitleDivClasses}>
        <Typography variant='h4' className={TitleTextClasses}>
          <FormattedMessage id='patients.Title' />
        </Typography>
        <div className={TextDivClasses}>
          <div className={TextClasses}>
            <Text>
              <FormattedMessage id='patients.ActivePatients' />: {activePatientsNumber}
            </Text>
          </div>
          <div className={TextClasses}>
            <Text>
              <FormattedMessage id='patients.InactivePatients' />: {patientsNumber - activePatientsNumber}
            </Text>
          </div>
          <div className={TextClasses}>
            <Text>
              <FormattedMessage id='patients.AllPatients' />: {patientsNumber}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
}
