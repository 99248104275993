/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'moment/locale/de';
import classNames from 'classnames/bind';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import type {
  Medication as MedicationType,
  Preparation as PreparationType,
  PreparationListItem as PreparationListItemType,
} from '../../../types';
import { Button, Icon, InputField, Loader } from '../../../components/atoms';
import { Dialog } from '../../../components/atoms/Dialog/Dialog';
import style from './Medication.module.scss';
import {
  fetchPreparations,
  patientSliceSelector,
  setChosenPreparationListItem,
  setPatientInfo,
} from '../patientOverviewSlice';
import { setMedication } from '../../../utils/apiCalls';
import { PrescriptionService } from '../../../services';

const cx = classNames.bind(style);
type MedicationProps = {
  patientInfo: any;
  prevMedication: MedicationType[];
};

const GreenHeading = cx('GreenHeading');
const TextLabel = cx('TextLabel');
const TextBold = cx('TextBold');

export function Medication({ prevMedication, patientInfo }: MedicationProps): JSX.Element {
  const pageClasses = cx({ Medication: true });
  const dispatch = useDispatch();
  const { preparations, loadPatientInfo } = useSelector(patientSliceSelector);
  const [IsSaving, setSaving] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState(false);
  const [medicationToDelete, setMedicationToDelete] = useState<string>();
  const [flowers, setFlowers] = useState<PreparationType[]>();
  const [extracts, setExtracts] = useState<PreparationType[]>();
  const [currentPreparation, setCurrentPreparation] = useState<PreparationListItemType[]>([]);
  const [isPrescriptionModalOpen, setIsPrescriptionModalOpen] = useState(false);
  const [prescriptionPreparations, setPrescriptionPreparations] = useState<
    { preparationText: string; preparationListItemId: number }[]
  >([]);
  const [medicationIsSaved, setMedicationIsSaved] = useState<boolean>(true);
  const [prescriptionIsLoading, setPrescriptionIsLoading] = useState<boolean>(false);

  const dosageUnitTextArray: string[] = ['g', 'ml'];

  const addMedicine = async () => {
    const newId = uuidv4();
    const newEmptyPreparation = {
      dosageMax: '',
      dosageSingle: '',
      dosageUnit: 'g',
      preparationId: newId,
      preparationQuestion: [],
      quantity: '',
      start: '',
      end: '',
      manufacturer: '',
      name: '',
      cultivar: '',
      thc: '',
      cbd: '',
      type: 0,
      form: 0,
    };
    setMedicationIsSaved(false);
    if (currentPreparation.length === 0) {
      setCurrentPreparation([newEmptyPreparation]);
    } else {
      setCurrentPreparation([...currentPreparation, newEmptyPreparation]);
    }
  };

  const handleDeleteClick = (id: string) => {
    setShowDialog(true);
    setMedicationToDelete(id);
    setMedicationIsSaved(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const preparationPickerOnChange = (item: any, index: number) => {
    if (!item) {
      return;
    }
    setMedicationIsSaved(false);
    const foundPrep = preparations.find((prep: { id: any }) => prep.id === item.value);
    if (!foundPrep) {
      return;
    }
    const newObj = {
      ...foundPrep,
      name: foundPrep.name,
      cultivar: foundPrep.cultivar,
      manufacturer: foundPrep.manufacturer,
      thc: foundPrep.thc,
      cbd: foundPrep.cbd,
      preparationId: foundPrep.id,
      form: foundPrep.form,
      type: foundPrep.type,
    };
    delete newObj.id; // if we dont remove it, it will override currentMedicationId
    setCurrentPreparation(
      currentPreparation.map((medication, i) => (i === index ? { ...medication, ...newObj } : medication))
    );
  };

  const colorStyles = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: (styles: any) => ({
      ...styles,
      backgroundColor: '#f2f2f2',
      border: 'none',
      borderRadius: 0,
      fontSize: '12px',
      width: '55rem',
      boxShadow: 'none',
      height: '38px',
    }),
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const preparationPickerDefaultValue = (item: any) => {
    if (!item.name) {
      return { value: '', label: '' };
    }

    return {
      value: item.preparationId,
      label: `${item.name} | ${item.cultivar} (${item.thc}% thc / ${item.cbd}% cbd)`,
    };
  };

  const onConfirm = () => {
    setCurrentPreparation((medication: PreparationListItemType[]) => {
      const newMed = medication.filter((d: PreparationListItemType) => d.preparationId !== medicationToDelete);

      return [...newMed];
    });
    setShowDialog(false);
  };

  const onCancel = () => {
    setShowDialog(false);
  };

  const setNewMedication = (medicationId: any) => {
    // If no existing medication, create a new medication entry
    if (!patientInfo.medication?.length) {
      const newMedicationObject = {
        id: medicationId,
        start: new Date().toISOString(),
        end: '',
        patientId: patientInfo.id,
        preparationListItem: currentPreparation,
      };

      dispatch(setPatientInfo({ ...patientInfo, medication: [newMedicationObject] }));
      dispatch(setChosenPreparationListItem(currentPreparation));
      setMedicationIsSaved(false);
    } else {
      // Filter out active medications (those without an end date)
      const activeMedication = patientInfo.medication.filter((med: { start: any; end: any }) => med.start && !med.end);

      // Mark active medication as ended, if any
      const endedMedication =
        activeMedication.length > 0
          ? {
              id: activeMedication[0].id || '',
              start: activeMedication[0].start,
              end: new Date().toISOString(),
              patientId: activeMedication[0].patientId,
              preparationListItem: activeMedication[0].preparationListItem,
            }
          : {};

      // Create the new medication entry
      const newMedicationObject = {
        id: medicationId,
        start: new Date().toISOString(),
        end: '',
        patientId: patientInfo.id,
        preparationListItem: currentPreparation,
      };

      // Append the ended medication if it exists, followed by the new one
      const updatedMedicationList =
        activeMedication.length > 0
          ? [...prevMedication, endedMedication, newMedicationObject]
          : [...prevMedication, newMedicationObject];

      dispatch(setPatientInfo({ ...patientInfo, medication: updatedMedicationList }));
      dispatch(setChosenPreparationListItem(currentPreparation));
    }
  };

  const validate = () => {
    let error = false;

    currentPreparation.forEach((medication: PreparationListItemType) => {
      const { preparationId, name, dosageUnit, quantity, dosageSingle, dosageMax } = medication;

      const newError = {
        noPrepId: !preparationId,
        noPrepName: !name,
        noDosageUnit: !dosageUnit,
        quantityLowerThanZero: dosageUnit === 'g' && (!quantity || Number(quantity) <= 0),
        dosageSingleNoLettersNoZero:
          dosageSingle === '' || Number(dosageSingle) <= 0 || /^[0-9,.]*$/.test(dosageSingle) === false,
        dosageMaxNoLettersNoZero: dosageMax === '' || Number(dosageMax) <= 0 || /^[0-9,.]*$/.test(dosageMax) === false,
      };

      if (Object.values(newError).find((e) => e)) {
        error = true;
      }
    });

    return error;
  };

  const prepareDataForSending = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = {
      patientId: patientInfo.id,
      preparationListItem: [],
    };

    currentPreparation.forEach((medication: PreparationListItemType) => {
      const { quantity, dosageSingle, dosageMax, extractQuantity } = medication;
      const preparationID = preparations.find((d: PreparationType) => medication.preparationId === d.id);
      const unit = dosageUnitTextArray[medication.type];
      const newObj = {
        preparationId: preparationID?.id,
        dosageUnit: unit,
        quantity: extractQuantity || quantity,
        dosageSingle,
        dosageMax,
      };
      data.preparationListItem.push(newObj);
    });

    return data;
  };

  const save = async () => {
    if (currentPreparation.length === 0) {
      return;
    }
    setMedicationIsSaved(false);

    const newError = validate();
    if (newError) {
      toast.warn('Kontrollieren Sie Ihre Angaben');

      return;
    }

    const data = prepareDataForSending();

    setSaving(true);
    const resp = await setMedication(data);
    setSaving(false);
    if (resp.error) {
      toast.error('Etwas ist schiefgelaufen');
    } else {
      toast.success('Medikation gespeichert!');
      setNewMedication(resp.data.medicationId);
      setMedicationIsSaved(true);
    }
  };

  const openCreatePrescriptionModal = () => {
    const preparationForPrescription: { preparationText: string; preparationListItemId: number }[] = [];
    currentPreparation.forEach((medication: PreparationListItemType) => {
      const text = `${medication.name} (${medication.cultivar}), ${medication.quantity}${medication.dosageUnit}, unzerkleinert, max. TD ${medication.dosageMax}${medication.dosageUnit}, ED ${medication.dosageSingle}${medication.dosageUnit}, verdampfen und inhalieren`;
      preparationForPrescription.push({
        preparationText: text,
        preparationListItemId: Number(medication.id),
      });
    });
    setPrescriptionPreparations(preparationForPrescription);
    setIsPrescriptionModalOpen(true);
  };

  const changePrescriptionPreparations = (e: any, index: number) => {
    const newPreparations = [...prescriptionPreparations];
    newPreparations[index].preparationText = e.target.value;
    setPrescriptionPreparations(newPreparations);
  };

  const createPrescription = async () => {
    setPrescriptionIsLoading(true);
    const resp = await PrescriptionService.createPrescription({
      patientId: patientInfo.id,
      preparations: prescriptionPreparations,
    });

    if (resp.error) {
      toast.error('Etwas ist schiefgelaufen');
    } else {
      toast.success('Rezept angelegt!');
      setIsPrescriptionModalOpen(false);
      setPrescriptionIsLoading(false);
      setPrescriptionPreparations([]);
    }
  };

  const getPreparation = () => {
    if (preparations.length > 0) {
      const typeFlowers = preparations.filter((medicine: PreparationType) => medicine.type === 0);
      const typeExtracts = preparations.filter((medicine: PreparationType) => medicine.type === 1);

      setFlowers(typeFlowers);
      setExtracts(typeExtracts);
    }
  };

  const getCurrentMedication = (medication: MedicationType[]) => {
    const foundMedication = medication.filter(
      (med: MedicationType) => med.start !== undefined && (med.end === '' || med.end === undefined)
    );

    const newMed = foundMedication[0]?.preparationListItem;
    if (newMed) {
      dispatch(setChosenPreparationListItem(newMed));
      setCurrentPreparation(newMed);
    }
  };

  useEffect(() => {
    if (patientInfo.medication !== undefined && patientInfo.medication.length !== 0) {
      getCurrentMedication(patientInfo.medication);
    }
  }, []);

  useEffect(() => {
    if (currentPreparation !== undefined) {
      dispatch(setChosenPreparationListItem(currentPreparation));
    }
  }, [currentPreparation]);

  useEffect(() => {
    if (!preparations.length) {
      dispatch(fetchPreparations());
    }
    getPreparation();
  }, [preparations]);

  const handleMedicationTypeChange = (e: any, index: number) => {
    const newMedicationObj = {
      ...currentPreparation[index],
      form: 0,
      type: Number(e.target.value),
      quantity: '',
      name: '',
      dosageUnit: dosageUnitTextArray[Number(e.target.value)],
      cultivar: '',
      manufacturer: '',
      thc: '',
      cbd: '',
      preparationId: '',
    };
    setMedicationIsSaved(false);
    setCurrentPreparation(
      currentPreparation.map((medication, i) => (i === index ? { ...medication, ...newMedicationObj } : medication))
    );
  };

  const handleInputChange = (event: any, index: number) => {
    const { name, value } = event.currentTarget;
    setMedicationIsSaved(false);
    if (name === 'quantity') {
      setCurrentPreparation(
        currentPreparation.map((medication, i) => (i === index ? { ...medication, quantity: value } : medication))
      );
    } else if (name === 'extractQuantity') {
      setCurrentPreparation(
        currentPreparation.map((medication, i) =>
          i === index ? { ...medication, extractQuantity: value } : medication
        )
      );
    } else if (name === 'td') {
      setCurrentPreparation(
        currentPreparation.map((medication, i) => (i === index ? { ...medication, dosageMax: value } : medication))
      );
    } else if (name === 'ed') {
      setCurrentPreparation(
        currentPreparation.map((medication, i) => (i === index ? { ...medication, dosageSingle: value } : medication))
      );
    }
  };

  const isButtonDisabled = () =>
    patientInfo?.patientPharmacyRelation?.name === undefined ||
    patientInfo.patientPharmacyRelation?.name === '' ||
    medicationIsSaved === false;

  return (
    <>
      {isPrescriptionModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            bottom: '0',
            zIndex: '999',
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'center', fontSize: '15px' }}>
            <div
              style={{
                backgroundColor: '#fff',
                padding: '3rem 2rem',
                margin: '10rem 0 0 0',
                width: '600px',
              }}
            >
              <h1>Rezept erstellen</h1>
              <p style={{ marginTop: '3rem' }}>
                <span className={style.PreparationText}>PRÄPARATE</span>
                <span className={style.PreparationSubText}>Welcher Text soll auf dem Rezept stehen?</span>
              </p>
              <div>
                {prescriptionPreparations.map(
                  (preparation: { preparationText: string; preparationListItemId: number }, index) => (
                    <textarea
                      key={index}
                      style={{
                        width: '100%',
                        height: '55px',
                        padding: '10px',
                        border: 'none',
                        backgroundColor: '#f2f2f2',
                        marginTop: '1rem',
                      }}
                      onChange={(e) => changePrescriptionPreparations(e, index)}
                      defaultValue={preparation.preparationText}
                    />
                  )
                )}
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
                <Button
                  children={'Rezept an Zoho übermitteln'}
                  className={`${style.PreparationButton} ${prescriptionIsLoading ? style.ButtonLoading : style.ButtonClass}`}
                  type={'primary'}
                  spinner={'primary'}
                  onClick={createPrescription}
                  disabled={prescriptionIsLoading}
                />
                <Button
                  children={'Schließen'}
                  className={style.PreparationButton}
                  type={'secondary'}
                  spinner={'none'}
                  onClick={() => setIsPrescriptionModalOpen(false)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={pageClasses}>
        {loadPatientInfo ? (
          <Loader />
        ) : (
          <>
            <div>
              <h1 className={GreenHeading}>APOTHEKE DES PATIENTEN</h1>
              <div className={style.MainContainer}>
                <div className={style.ApothekeContainer}>
                  {patientInfo?.patientPharmacyRelation ? (
                    <p className={TextLabel}>
                      <b>{patientInfo.patientPharmacyRelation.name}</b> - {patientInfo.patientPharmacyRelation.city}
                    </p>
                  ) : (
                    <p className={`TextLabel ${style.NoPharmacyText}`}>nicht gesetzt</p>
                  )}
                </div>
              </div>
            </div>
            <div>
              <h1 className={GreenHeading}>AKTUELLE MEDIKATION</h1>
              <div className={style.MainContainer}>
                <div className={style.MedicineContainer}>
                  {currentPreparation?.map((medication, index: number) => (
                    <div className={style.SpaceBetweenContainer} key={medication.preparationId}>
                      <div className={style.LeftRowContainer}>
                        <div>
                          <p className={TextLabel}>Typ</p>
                          <select
                            className={style.InputClass}
                            value={String(medication.type)}
                            onChange={(e) => handleMedicationTypeChange(e, index)}
                          >
                            <option value={'0'}>Blüten</option>
                            <option value={'1'}>Extrakt</option>
                          </select>
                        </div>
                        <div className={style.ColumnContainer}>
                          <p className={TextLabel}>Präparat</p>
                          {medication.type === 0 && (
                            <Select
                              value={preparationPickerDefaultValue(medication)}
                              styles={colorStyles}
                              options={flowers?.map((med) => ({
                                value: med.id,
                                label: `${med.name} | ${med.cultivar} (${med.thc}% thc / ${med.cbd}% cbd)`,
                              }))}
                              onChange={(e) => preparationPickerOnChange(e, index)}
                            />
                          )}
                          {medication.type === 1 && (
                            <Select
                              value={preparationPickerDefaultValue(medication)}
                              styles={colorStyles}
                              options={extracts?.map((med) => ({
                                value: med.id,
                                label: `${med.name} | ${med.cultivar} (${med.thc}% thc / ${med.cbd}% cbd)`,
                              }))}
                              onChange={(e) => preparationPickerOnChange(e, index)}
                            />
                          )}
                        </div>
                        {medication.type === 0 && (
                          <div className={style.ColumnContainer}>
                            <p className={TextLabel}>Gesamtmenge</p>
                            <InputField
                              value={medication.quantity}
                              type='text'
                              name='quantity'
                              className={style.SmallInputClass}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </div>
                        )}
                        {medication.type === 1 && (
                          <div className={style.ColumnContainer}>
                            <p className={TextLabel}>Gesamtmenge</p>
                            <InputField
                              value={medication.extractQuantity}
                              type='text'
                              disabled={true}
                              name='extractQuantity'
                              className={style.SmallInputClass}
                              onChange={(e) => handleInputChange(e, index)}
                            />
                          </div>
                        )}
                        <div className={style.ColumnContainer}>
                          <p className={TextLabel}>TD</p>
                          <InputField
                            type={'decimal'}
                            name='td'
                            inputMode={'decimal'}
                            value={medication.dosageMax}
                            className={style.SmallInputClass}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </div>
                        <div className={style.ColumnContainer}>
                          <p className={TextLabel}>ED</p>
                          <InputField
                            type={'decimal'}
                            name='ed'
                            inputMode={'decimal'}
                            className={style.SmallInputClass}
                            value={medication.dosageSingle}
                            onChange={(e) => handleInputChange(e, index)}
                          />
                        </div>
                        {medication.type === 0 && <span className={style.DosageUnitContainer}>Gramm (g)</span>}
                        {medication.type === 1 && <span className={style.DosageUnitContainer}>Milliliter (ml)</span>}
                      </div>
                      <div style={{ alignSelf: 'flex-end' }}>
                        <div
                          className={style.DeleteContainer}
                          onClick={() => handleDeleteClick(medication.preparationId)}
                        >
                          <Icon icon={'trash'} size={16} color={'rgb(220, 0, 0)'} />
                          <span className={style.DeleteText}>entfernen</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className={style.AddMedicineContainer}>
                  <div className={style.AddMedicineButton} onClick={addMedicine}>
                    <p className={style.PlusSymbol}>+</p>
                    <p>Präparat hinzufügen</p>
                  </div>
                </div>
              </div>
              <div className={style.ButtonContainer}>
                <Button
                  children={'Änderungen speichern'}
                  className={IsSaving ? style.ButtonLoading : style.ButtonClass}
                  type={'primary'}
                  spinner={'primary'}
                  onClick={save}
                  disabled={IsSaving}
                />
                <span style={{ marginLeft: '1rem' }}>
                  <Button
                    children={'Rezept erstellen'}
                    className={`${style.ButtonClass}`}
                    type={'primary'}
                    spinner={'none'}
                    onClick={openCreatePrescriptionModal}
                    disabled={isButtonDisabled()}
                  />
                </span>
              </div>
            </div>
            {prevMedication.length > 0 && (
              <div className={style.prevMedications}>
                {prevMedication.map((med: MedicationType) => (
                  <div className={style.MedicineContainer} key={med.start}>
                    <h1 className={GreenHeading}>
                      Medikation <b>Bis {med.end && moment(med.end).format('L')}</b>
                    </h1>
                    {med?.preparationListItem?.map((m: PreparationListItemType, index: number) => (
                      <div className={style.MedicationHistoryRow} key={index}>
                        <div className={style.ColumnContainer}>
                          <p className={TextBold}>
                            {m.name} | {m.cultivar} ({m.thc}% thc / {m.cbd}% cbd)
                          </p>
                        </div>
                        <div className={style.ColumnContainer}>
                          {m.dosageUnit === 'g' && (
                            <p className={TextLabel}>
                              Gesamtmenge: {m.quantity} {m.dosageUnit}
                            </p>
                          )}
                          {m.dosageUnit === 'ml' && (
                            <p className={TextLabel}>{`Gesamtmenge: ${m.extractQuantity} ml`}</p>
                          )}
                        </div>

                        <div className={style.ColumnContainer}>
                          <p className={TextLabel}>
                            TD: {m.dosageMax} {m.dosageUnit}
                          </p>
                        </div>
                        <div className={style.ColumnContainer}>
                          <p className={TextLabel}>
                            ED: {m.dosageSingle} {m.dosageUnit}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        {showDialog && (
          <Dialog
            message={'Möchten Sie das Präparat wirklich entfernen?'}
            type='negativeDialog'
            buttonText='Ja'
            negativeButtonText={'Nein'}
            onConfirm={onConfirm}
            onCancel={onCancel}
          />
        )}
      </div>
    </>
  );
}
