/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-nested-ternary */
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Grid from '@mui/material/Grid';
import classNames from 'classnames/bind';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import 'moment/locale/de';
import moment from 'moment';
import _ from 'lodash';
import type { Slot as SlotType } from '../../../types/slot.type';
import style from './Availability.module.scss';
import { Button, Icon, Loader } from '../../../components/atoms';
import { availabilitySliceSelector, fetchAppointments, fetchSlots, addSlots, editSlots } from './availabilitySlice';
import { updateSlot } from '../../../utils/apiCalls';
import Slots from './Slots';
import { UserService } from '../../../services';

const cx = classNames.bind(style);
const pageClasses = cx({ Availability: true });

export function Availability(): JSX.Element {
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState('');
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedDateSlots, setSelectedDateSlots] = useState([] as SlotType[]);
  const [daysInSelectedMonth, setDaysInSelectedMonth] = useState<string[]>([]);
  const [doctorData, setDoctorData] = useState<any>(undefined);

  const dispatch = useDispatch();
  const { appointments, slots, loadAvailability } = useSelector(availabilitySliceSelector);

  const nextMonth = () => {
    const month = new Date(selectedMonth);
    const newMonth = new Date(month.getFullYear(), month.getMonth() + 1, 1);
    setSelectedMonth(new Date(newMonth));
    setClicked(false);
    setSelectedDateSlots([]);
  };

  const prevMonth = () => {
    const month = new Date(selectedMonth);
    const newMonth = new Date(month.getFullYear(), month.getMonth() - 1, 1);

    setSelectedMonth(new Date(newMonth));
    setClicked(false);
    setSelectedDateSlots([]);
  };

  const getDoctorData = async () => {
    const ninoxId = localStorage.getItem('ninoxId');
    const response = await UserService.getDoctorData(Number(ninoxId));
    if (response.error) {
      console.log('ERROR in getDoctorData', response);
      return;
    }
    setDoctorData(response.data);
  };

  useEffect(() => {
    if (!appointments.length) {
      const payload: any = { future: false };

      dispatch(fetchAppointments(payload));
    }
    if (!slots.length) {
      const payload: any = { startDate: selectedMonth };

      dispatch(fetchSlots(payload));
    }
    getDoctorData();
  }, []);

  useEffect(() => {
    setCurrentMonth(
      new Date(selectedMonth).toLocaleString('de-DE', {
        month: 'long',
        year: 'numeric',
      })
    );
    const daysInMonth: string[] = [];
    const monthDate = moment(selectedMonth).startOf('month'); // change to a date in the month of interest
    _.times(monthDate.daysInMonth(), (n) => {
      daysInMonth.push(monthDate.format('DD-dd').toUpperCase()); // your format
      monthDate.add(1, 'day');
    });
    setDaysInSelectedMonth(daysInMonth);

    const payload: any = { startDate: selectedMonth };
    dispatch(fetchSlots(payload));
  }, [selectedMonth]);

  const addSlot = () => {
    const newID: string = uuidv4();
    setSelectedDateSlots((prev: SlotType[]) => [
      ...prev,
      {
        id: '',
        startDate: '',
        endDate: '',
        slotType: -1,
        tempID: newID,
        startDateSetHour: '',
        startDateSetMin: '',
        endDateSetHour: '',
        endDateSetMin: '',
      },
    ]);
  };

  const onSelectDateSlots = (value: number): void => {
    setClicked(true);
    setSelectedDate(`${value}. ${currentMonth}`);

    const newSlots = slots.filter((s: SlotType) => new Date(s.startDate).getDate() === value);
    newSlots.sort(
      (a: { startDate: string | number | Date }, b: { startDate: string | number | Date }) =>
        new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    );
    setSelectedDateSlots([...newSlots]);

    if (newSlots.length === 0) {
      addSlot();
    }
  };

  const checkIfDatesOverlap = (): boolean => {
    const datesOverlap = false;

    for (let i = 0; i < selectedDateSlots.length; i += 1) {
      for (let j = i + 1; j < selectedDateSlots.length; j += 1) {
        const StartDate1 = new Date(selectedDateSlots[i].startDate).getTime();
        const StartDate2 = new Date(selectedDateSlots[j].startDate).getTime();
        const EndDate1 = new Date(selectedDateSlots[i].endDate).getTime();
        const EndDate2 = new Date(selectedDateSlots[j].endDate).getTime();
        if (StartDate1 < EndDate2 && StartDate2 < EndDate1) {
          return true;
        }
      }
    }

    return datesOverlap;
  };

  const validate = () => {
    let error = false;

    selectedDateSlots.forEach((element: SlotType) => {
      const { startDate, endDate } = element;

      if (startDate === '') {
        error = true;
      }
      if (endDate === '') {
        error = true;
      }
      if (new Date(startDate).getTime() >= new Date(endDate).getTime()) {
        error = true;
      }
    });

    return error;
  };

  const createSlots = async (newSlots: SlotType[]) => {
    const slotsToBeCreated: SlotType[] = [];
    newSlots.forEach((element) => {
      const newObj = {
        startDate: '',
        endDate: '',
        slotType: -1,
        slotSubType: -1,
        practiceId: -1,
      };

      newObj.startDate = new Date(element.startDate).toISOString();
      newObj.endDate = new Date(element.endDate).toISOString();
      newObj.practiceId = Number(element.practiceId);
      // newObj.slotSubType = element.practiceId === 0 ? 1 : 0; // when practice ID is 0 it's an online appointment
      slotsToBeCreated.push(newObj);
    });
    const payload: any = { slots: slotsToBeCreated };
    dispatch(addSlots(payload));
    setSelectedDateSlots([]);
  };

  const updateSlots = async (slotsToBeUpdated: SlotType[]) => {
    const promises = slotsToBeUpdated.map(
      (x) =>
        new Promise((resolve, reject) => {
          updateSlot(String(x.id), x)
            .then((data) => resolve(data))
            .catch((err) => reject(err));
        })
    );
    Promise.all(promises)
      .then(() => {
        toast.success('Slot(s) Änderungen gespeichert!');
        dispatch(editSlots(slotsToBeUpdated));

        setSelectedDateSlots([]);
      })
      .catch((err) => {
        toast.error('Etwas ist schiefgelaufen');
      });
  };

  const save = async () => {
    setLoading(true);
    const newError = validate();
    if (newError) {
      toast.warn('Kontrollieren Sie Ihre Angaben');
      setLoading(false);
      return;
    }
    const datesOverlap = checkIfDatesOverlap();
    if (datesOverlap === true) {
      toast.warn('eingetragene Zeiträume überlappen sich');
      setLoading(false);

      return;
    }

    const newSlots = selectedDateSlots.filter((e) => e.id === '');
    if (newSlots.length > 0) {
      createSlots(newSlots);
    }

    const slotsToBeUpdated = selectedDateSlots.filter((e) => e.id);
    if (slotsToBeUpdated.length > 0) {
      updateSlots(slotsToBeUpdated);
    }
    setLoading(false);
  };

  // We print the calendar and add the boxes with their specified day from the daysInSelectedMonth array
  // daysInSelectedMonth = [1-SA, 2-SU, 3-MO...]  -  we map it and split the name of the week at the top and the number at the bottom
  // inside every box we count the slots the doctor has by checking the startDate and print the green lines
  const renderMonthDays = () =>
    daysInSelectedMonth.map((value, index) => (
      <Grid key={index} item className={style.GridContainer}>
        <p className={style.Days}>{value.split('-').pop()}</p>
        <h4 className={style.Days}>{value.split('-')[0]}</h4>
        <button
          className={
            value.split('-').pop() === 'SA' || value.split('-').pop() === 'SO'
              ? style.WeekendBoxButton
              : style.BoxButton
          }
          style={clicked ? { border: '2px' } : { border: 'none' }}
          onClick={() => onSelectDateSlots(Number(value.split('-')[0]))}
        >
          <>
            {slots.map((slot: { startDate: string | number | Date; id: any; tempID: any }) =>
              new Date(slot.startDate).getDate() === Number(value.split('-')[0]) ? (
                <div className={style.Tile} key={slot.id || slot.tempID} />
              ) : null
            )}
          </>
        </button>
      </Grid>
    ));

  const wrapperSetSelectedDateSlots = useCallback(
    (value: SetStateAction<SlotType[]>) => {
      setSelectedDateSlots(value);
    },
    [setSelectedDateSlots]
  );

  return (
    <div className={pageClasses}>
      {loadAvailability ? (
        <Loader />
      ) : (
        <div className={style.MainContainer}>
          <div className={style.RowContainer}>
            <div>
              <button className={style.ArrowButtonLeft} onClick={prevMonth}>
                <Icon icon={'chevron'} size={20} />
              </button>
            </div>
            <div>
              <button className={style.ArrowButtonRight} onClick={nextMonth}>
                <Icon icon={'chevron'} size={20} />
              </button>
            </div>
            <p className={style.DateHeader}>{currentMonth}</p>
          </div>
          <Grid container spacing={1}>
            {renderMonthDays()}
          </Grid>

          {selectedDateSlots.length > 0 ? (
            <div className={style.MainSlotsContainer}>
              <h3>Ihre Verfügbarkeiten am {selectedDate}</h3>
              <div className={style.SplitRows}>
                {doctorData && (
                  <Slots
                    appointmentsTest={appointments}
                    selectedDateSlots={selectedDateSlots}
                    setSelectedDateSlots={wrapperSetSelectedDateSlots}
                    selectedMonth={selectedMonth}
                    selectedDate={selectedDate}
                    isAllowedToSetFirstPresentationSlots={doctorData.isAllowedToSetFirstPresentationSlots}
                  />
                )}
              </div>
              <div className={style.ButtonContainer}>
                <Button
                  children={'Speichern'}
                  className={style.ButtonClass}
                  type={'primary'}
                  onClick={save}
                  disabled={loading}
                  spinner='secondary'
                />
                <div className={style.AddSlotContainer}>
                  <div className={style.AddSlotButton} onClick={addSlot}>
                    <p className={style.PlusSymbol}>+</p>
                    <p>Zeit-Slot hinzufügen</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={style.Message}>
              <p>Bitte wählen Sie einen Tag, um Ihre Verfügbarkeiten zu bearbeiten.</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
