/* eslint-disable import/prefer-default-export */
import { useState } from 'react';
import classNames from 'classnames/bind';
import { Value } from 'sass';
import { Icon } from '../Icon/Icon';
import style from './Checkbox.module.scss';

const cx = classNames.bind(style);

interface CheckboxItem {
  key: string;
  label: string;
}

interface RadioProps {
  name: string;
  items: CheckboxItem[];
  layout: 'horizontal' | 'vertical';
  onChange: (checkedKeys: string[]) => void;
  value: string[];
}

const Wrapper = cx({ CheckboxWrapper: true });
const CheckboxStyle = cx({ CheckboxInput: true });
const CheckboxDelegate = cx({ CheckboxDelegate: true });

export const Checkbox = ({ name, items, layout, onChange, value }: RadioProps) => {
  const [keys, setKeys] = useState<string[]>([]);
  const refs: any = [];

  const toggleCheck = (key: string): void => {
    const ref = refs.find((refItem: any) => refItem.key === key);
    ref.ref.dispatchEvent(new MouseEvent('click'));
    let newKeys = keys.slice();
    if (newKeys.includes(key)) {
      newKeys = newKeys.filter((k) => k !== key);
    } else {
      newKeys.push(key);
    }
    setKeys(newKeys);
    onChange(newKeys);
  };

  return (
    <div style={{ display: 'flex', flexDirection: layout === 'vertical' ? 'column' : 'row', gap: 20 }}>
      {items.map(({ key, label }: CheckboxItem) => (
        <div className={Wrapper} key={key}>
          <input
            ref={(ref) => {
              const exists = refs.find((refItem: any) => refItem.key === key);
              if (!exists) {
                refs.push({ key, ref });
              }
            }}
            className={CheckboxStyle}
            id={key}
            type='checkbox'
            name={name}
            value={key}
            checked={value.includes(key)}
          />
          <div onClick={() => toggleCheck(key)} className={CheckboxDelegate}>
            <Icon icon='checked' color='#fff' />
          </div>
          <label onClick={() => toggleCheck(key)} htmlFor={key}>
            {label}
          </label>
        </div>
      ))}
    </div>
  );
};
