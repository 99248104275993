/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const GLOBALS_SLICE = 'GLOBALS_SLICE';

const initialState = {
  isLoading: false,
  language: navigator.language.split('-')[0], // browser local language,
  renderPatientOverview: -1 as number,
  isMainOrSubDoctor: true,
  showNavigationDialog: false,
  hasSavedTask: false,
  nextNavigation: '',
  renderPage: 'dashboard',
};

export const globalsSlice = createSlice({
  name: GLOBALS_SLICE,
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setRenderPatientOverview: (state, action) => {
      state.renderPatientOverview = action.payload;
    },
    setIsMainOrSubDoctor: (state, action) => {
      state.isMainOrSubDoctor = action.payload;
    },
    setShowNavigationDialog: (state, action) => {
      state.showNavigationDialog = action.payload;
    },
    setHasSavedtask: (state, action) => {
      state.hasSavedTask = action.payload;
    },
    setNextNavigation: (state, action) => {
      state.nextNavigation = action.payload;
    },
    setRenderPage: (state, action) => {
      state.renderPage = action.payload;
    },
  },
});

export const {
  setLanguage,
  setRenderPatientOverview,
  setIsMainOrSubDoctor,
  setShowNavigationDialog,
  setHasSavedtask,
  setNextNavigation,
  setRenderPage,
} = globalsSlice.actions;

export const globalSliceSelector = (state: any) => state.global;

export default globalsSlice.reducer;
