import { all, fork } from 'redux-saga/effects';
import watchAuthSaga from '../features/auth/authSaga';
import watchTasksSaga from '../features/dashboard/dashboardSaga';
import watchPatientsSaga from '../features/patientList/patientListSaga';
import watchAvailabilitySaga from '../features/profile/availability/availabilitySaga';
import watchPatientOverviewSaga from '../features/patientOverview/patientOverviewSaga';

export default function* rootSaga() {
  yield all([
    fork(watchAuthSaga),
    fork(watchPatientsSaga),
    fork(watchTasksSaga),
    fork(watchAvailabilitySaga),
    fork(watchPatientOverviewSaga),
  ]);

  // yield all([
  //   watchAuthSaga,
  // ]).map(fork);
}
