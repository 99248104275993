/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Icon } from '../../atoms';
import style from './PatientNavigation.module.scss';
import { patientSliceSelector } from '../../../features/patientOverview/patientOverviewSlice';
import type { Medication as MedicationType, PreparationListItem as PreparationListItemType } from '../../../types';

const cx = classNames.bind(style);

type PatientNavProps = {
  currentPage: string;
  setCurrentPage: React.Dispatch<React.SetStateAction<string>>;
};

export function PatientNavigation({ currentPage, setCurrentPage }: PatientNavProps): JSX.Element {
  const containerClasses = cx({ PatientNavigationContainer: true });
  const RowContainer = cx({ PatientNavigationRowContainer: true });
  const MainContainer = cx({ PatientNavigationMainContainer: true });
  const NavItemsContainer = cx({ PatientNavigationNavItemsContainer: true });
  const NameItemsContainers = cx({ PatientNavigationNameItemsContainers: true });
  const NameHeading = cx({ PatientNavigationNameHeading: true });
  const NavItems = cx({ PatientNavigationItems: true });
  const NavSelectedItem = cx({ PatientNavigationSelectedItem: true });

  const { patientInfo, chosenAvailablePrescription, chosenPreparationListItem } = useSelector(patientSliceSelector);

  const validate = (route: string) => {
    let savedMedication: PreparationListItemType[] = [];

    if (patientInfo.medication && patientInfo.medication.length !== 0) {
      const lastMedication = patientInfo.medication.filter(
        (medication: MedicationType) =>
          medication.start !== undefined && (medication.end === '' || medication.end === undefined)
      );
      if (lastMedication.length !== 0 && lastMedication !== undefined) {
        savedMedication = lastMedication[0]?.preparationListItem;
      }
    }

    if (currentPage === 'Medication') {
      if (_.isEqual(_.omit(savedMedication, ['id']), _.omit(chosenPreparationListItem, ['id']))) {
        setCurrentPage(route);
      } else {
        // eslint-disable-next-line no-unused-expressions, no-alert
        window.confirm('Sie haben ungespeicherte Änderungen\nWollen Sie trotzdem die Seite verlassen?')
          ? setCurrentPage(route)
          : () => null;
      }
    } else if (currentPage === 'FollowRecipe') {
      if (patientInfo.availableFollowUpPrescription === chosenAvailablePrescription) {
        setCurrentPage(route);
      } else {
        // eslint-disable-next-line no-unused-expressions, no-alert
        window.confirm('Sie haben ungespeicherte Änderungen\nWollen Sie trotzdem die Seite verlassen?')
          ? setCurrentPage(route)
          : () => null;
      }
    } else {
      setCurrentPage(route);
    }
  };

  return (
    <div className={containerClasses}>
      <div className={RowContainer}>
        <Icon icon={'account-plus'} size={60} />
        <div className={MainContainer}>
          <div className={NameItemsContainers}>
            <p className={NameHeading}>
              {`${patientInfo?.firstname || 'Patient'} ${patientInfo?.lastname || 'Name'}`}
              {(patientInfo.id && `(ID: ${patientInfo.id})`) || ''}
            </p>
          </div>
          <div className={NavItemsContainer}>
            <p
              className={currentPage === 'Overview' ? NavSelectedItem : NavItems}
              onClick={() => {
                validate('Overview');
              }}
            >
              <FormattedMessage id='patient.Overview' />
            </p>
            <p
              className={currentPage === 'Record' ? NavSelectedItem : NavItems}
              onClick={() => {
                validate('Record');
              }}
            >
              <FormattedMessage id='patient.Record' />
            </p>
            <p
              className={currentPage === 'Medication' ? NavSelectedItem : NavItems}
              onClick={() => {
                validate('Medication');
              }}
            >
              <FormattedMessage id='patient.Medication' />
            </p>
            <p
              className={currentPage === 'Appointments' ? NavSelectedItem : NavItems}
              onClick={() => {
                validate('Appointments');
              }}
            >
              <FormattedMessage id='patient.Appointments' />
            </p>
            <p
              className={currentPage === 'Tracking' ? NavSelectedItem : NavItems}
              onClick={() => {
                validate('Tracking');
              }}
            >
              TRACKING
            </p>
            <p
              className={currentPage === 'FollowRecipe' ? NavSelectedItem : NavItems}
              onClick={() => {
                validate('FollowRecipe');
              }}
            >
              <FormattedMessage id='patient.FollowRecipe' />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
