import axios from 'axios';
import type { AxiosReturn, AxiosOptions } from '../types/return.types';

const getRequestOptions = ({
  method,
  url,
  isMultipart,
  token,
  body,
}: {
  method: any;
  url: string;
  isMultipart: boolean;
  token?: string;
  body: any;
}) => {
  const options: AxiosOptions = {
    method,
    url,
    mode: 'cors',
    credentials: 'true',
    withCredentials: true,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': isMultipart ? 'multipart/form-data' : 'application/json',
      'Cache-Control': 'no-store, max-age=0',
      Authorization: token,
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    data: undefined,
  };

  if (body && method !== 'GET') {
    options.data = !isMultipart ? JSON.stringify(body) : body;
  }
  return options;
};

/**
 * Wrapper function for making request
 * @param { Object } params Object containing key/value parameters to pass axios request()
 * @returns {Promise<null|any>}
 */

// eslint-disable-next-line import/prefer-default-export
export const request = async ({
  url,
  method = 'GET',
  body,
  token,
  isMultipart = false,
}: {
  method: any;
  url: string;
  isMultipart?: boolean;
  token?: string;
  body: any;
}) => {
  const requestOptions: AxiosOptions = getRequestOptions({
    method,
    url,
    isMultipart,
    body,
    token,
  });
  if (isMultipart) {
    requestOptions.headers['Content-Type'] = 'multipart/form-data';
  }
  try {
    const response: AxiosReturn = await axios.request(requestOptions);

    if (!response.error) {
      return response;
    }
    return null;
  } catch (error) {
    console.error('REQUEST ERROR ', error);
    throw error;
  }
};
