/* eslint-disable @typescript-eslint/indent */
/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { UserService } from '../../../services';
import type { DoctorAppointments } from '../../../types';
import { Button } from '../../atoms';
import { Checkbox } from '../../atoms/Checkbox/Checkbox';
import { Radio } from '../../atoms/Radio/Radio';
import style from './AppointmentItems.module.scss';
import { setHasSavedtask } from '../../../app/appSlice';
import { setTaskAppointments, tasksSliceSelector } from '../../../features/dashboard/dashboardSlice';

const cx = classNames.bind(style);

interface EditableAppointmentFormProps {
  appointment: DoctorAppointments;
  setIsAppointmentUpdated: (value: boolean) => void;
}

const styles = {
  itemDiv: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 13,
    gap: 10,
  },
  labelDiv: {
    fontWeight: 'bold',
    minWidth: 150,
    paddingRight: 10,
  },
  listItem: {
    marginTop: 8,
    marginLeft: 130,
  },
  greenLine: {
    borderBottom: '1px solid #28a745',
    paddingBottom: 25,
    marginBottom: 15,
  },
};

const InputClass = cx({ AppointmentItemsInputClass: true });
const TextArea = cx({ AppointmentItemsTextArea: true });
const TextAreaContainer = cx({ AppointmentItemsTextAreaContainer: true, AppointmentItemsTextArea: true });
const RoundedButton = cx({ AppointmentItemsRoundedButton: true });
const UnderlinedButton = cx({ AppointmentItemsUnderlinedButton: true });

const rezeptItems = [
  { key: 'created', label: 'ausgestellt' },
  { key: 'eRx', label: 'e-Rezept ausgestellt' },
  { key: 'notNeeded', label: 'kein Rezept' },
];

const invoiceItems = [
  { key: 'regular', label: 'Nach Standard abrechnen' },
  { key: 'other', label: 'Individuelle Abrechnung' },
];

const supportInfoItems = [
  { key: 'documentsNeeded', label: 'Dokumente nachreichen' },
  { key: 'idCard', label: 'Patientenausweis gewünscht' },
];

export const EditableAppointmentForm = ({ appointment, setIsAppointmentUpdated }: EditableAppointmentFormProps) => {
  const [status, setStatus] = useState<string>('default');
  const [supportInfo, setSupportInfo] = useState<string[]>([]);
  const [receipts, setReceipt] = useState<string>('');
  const [invoice, setInvoice] = useState<string>('regular');
  const [invoiceDetails, setInvoiceDetails] = useState<string>('');
  const [comments, setComments] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const { taskAppointments } = useSelector(tasksSliceSelector);

  const dispatch = useDispatch();

  const statusIdToText = (id: number): string => {
    switch (id) {
      case 1:
        return 'tookPlace';
      case 2:
        return 'excused';
      case 3:
        return 'cancelled';
      case 4:
        return 'paymentExpired';
      case 5:
        return 'paymentPending';
      default:
        return 'default';
    }
  };

  const todoIdToText = (todoIds: number[]): string[] => {
    const todos: string[] = [];
    todoIds.forEach((todoId: number) => {
      if (todoId === 1) {
        todos.push('documentsNeeded');
      }
      if (todoId === 2) {
        todos.push('idCard');
      }
    });

    return todos;
  };

  const prescriptionIdToText = (id: number): string => {
    switch (id) {
      case 1:
        return 'created';
      case 2:
        return 'notCreated';
      case 5:
        return 'eRxCreated';
      default:
        return '';
    }
  };

  const invoiceIdToText = (id: number): string => {
    switch (id) {
      case 1:
        return 'regular';
      case 2:
        return 'other';
      default:
        return '';
    }
  };

  const onStatusChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setStatus(event.target.value);
  };

  const onSupportInfoChange = (selectedKeys: string[]): void => {
    setSupportInfo(selectedKeys);
  };

  const onReceiptChange = (selectedKey: string): void => {
    setReceipt(selectedKey);
  };

  const onInvoiceChange = (selectedKey: string): void => {
    setInvoice(selectedKey);
  };

  const onCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setComments(event.target.value);
  };

  const onInvoiceDetailsChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setInvoiceDetails(event.target.value);
  };

  const isFormValid = () => status !== 'default' && receipts !== '';

  const save = async () => {
    if (isFormValid()) {
      setLoading(true);
      const ninoxId = localStorage.getItem('ninoxId');
      const appointmentData = {
        ...appointment,
        carriedOut: status,
        notes: comments,
        prescription: receipts,
        invoice,
        invoiceDetails,
        todos: supportInfo,
        statusSetBy: Number(ninoxId),
      };
      delete appointmentData.doctorTitle;
      const response = await UserService.setAppointmentStatus(appointment.id, appointmentData);
      setLoading(false);
      setIsAppointmentUpdated(true);
      if (response.error) {
        toast.error('Etwas ist schiefgelaufen');

        return;
      }
      dispatch(setHasSavedtask(true));
      const newArray = taskAppointments.openAppointments.filter((task: any) => task.id === !appointment.id);
      dispatch(setTaskAppointments(newArray));
      toast.success('Status gespeichert!');

      return;
    }
    toast.error('Missing required fields!');
  };

  const clearForm = () => {
    setComments('');
    setStatus('default');
    setReceipt('');
    setInvoice('');
    setInvoiceDetails('');
    setSupportInfo([]);
    setIsAppointmentUpdated(true);
  };

  useEffect(() => {
    if ('notes' in appointment) {
      setComments(appointment.notes!);
    }
    if ('prescription' in appointment) {
      setReceipt(prescriptionIdToText(appointment.prescription as number));
    }
    if ('invoice' in appointment) {
      setInvoice(invoiceIdToText(appointment.invoice as number));
    }
    if ('invoiceDetails' in appointment) {
      setInvoiceDetails(appointment.invoiceDetails!);
    }
    if ('todos' in appointment) {
      setSupportInfo(todoIdToText(appointment.todos! as number[]));
    }
    setStatus(statusIdToText(appointment.carriedOut as number));
  }, [appointment]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        gap: 20,
      }}
    >
      {appointment.patientInfo && (
        <div
          style={{
            ...styles.itemDiv,
            flexDirection: 'column',
            alignItems: 'flex-start',
            ...styles.greenLine,
          }}
        >
          <div style={styles.labelDiv}>Termin Info</div>
          <ul>
            {appointment.patientInfo.split('\n').map((line, index) => (
              <li style={styles.listItem} key={index}>
                {line}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div style={styles.itemDiv}>
        <div style={styles.labelDiv}>Termin bei</div>
        <div>{appointment.doctorName}</div>
      </div>
      <div style={styles.itemDiv}>
        <div style={styles.labelDiv}>Terminstatus</div>
        <div>
          <select value={status} onChange={onStatusChange} className={InputClass}>
            <option value={'default'}>-</option>
            <option value={'tookPlace'}>hat stattgefunden</option>
            <option value={'excused'}>ausgefallen (entschuldigt)</option>
            <option value={'cancelled'}>ausgefallen (unentschuldigt)</option>
          </select>
        </div>
      </div>
      <div style={styles.itemDiv}>
        <div style={styles.labelDiv}>Rezept</div>
        <div>
          <Radio value={receipts} name='receipt' items={rezeptItems} layout='horizontal' onChange={onReceiptChange} />
        </div>
      </div>
      <div style={styles.itemDiv}>
        <div style={styles.labelDiv}>Abrechnung</div>
        <div>
          <Radio value={invoice} name='invoice' items={invoiceItems} layout='horizontal' onChange={onInvoiceChange} />
        </div>
        {invoice === 'other' && (
          <div>
            <textarea
              value={invoiceDetails}
              onChange={onInvoiceDetailsChange}
              cols={90}
              rows={1}
              className={TextAreaContainer}
              placeholder='Details eingeben...'
            />
          </div>
        )}
      </div>
      <div style={styles.itemDiv}>
        <div style={styles.labelDiv}>Info für Support</div>
        <div>
          <Checkbox
            value={supportInfo}
            name='supportInfo'
            items={supportInfoItems}
            layout='horizontal'
            onChange={onSupportInfoChange}
          />
        </div>
      </div>
      <div style={{ ...styles.itemDiv, alignItems: 'flex-start' }}>
        <div style={styles.labelDiv}>Anmerkungen</div>
        <div>
          <textarea
            value={comments}
            onChange={onCommentChange}
            cols={185}
            rows={10}
            className={TextArea}
            placeholder='Details eingeben...'
          />
        </div>
      </div>
      <div style={styles.itemDiv}>
        <div style={styles.labelDiv} />
        <div style={styles.itemDiv}>
          <Button onClick={save} disabled={isLoading} spinner='primary' className={RoundedButton} type='primary'>
            Speichern
          </Button>
          <Button onClick={clearForm} disabled={isLoading} className={UnderlinedButton} type='secondary'>
            Abbrechen
          </Button>
        </div>
      </div>
    </div>
  );
};
