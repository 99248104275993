/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { AxiosResponse } from 'axios';
import {
  LOGIN_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  FETCH_PATIENTS,
  GET_PATIENT,
  GET_PATIENT_BY_ID,
  GET_DOCTOR_COMMENTS,
  GET_APPOINTMENTS,
  GET_PATIENT_DOCUMENTS,
  GET_DOCUMENT_FILED,
  GET_DOCTOR_TASKS,
  GET_DOCTOR_TASK_REQUEST,
  GET_DOCTOR_TASK_APPOINTMENTS,
  GET_DOCTOR_DATA,
  GET_PREPARATION,
  FETCH_MEDICATION,
  AVAILABLE_FOLLOW_UP_PRESCRIPTION,
  FOLLOW_UP_PRESCRIPTION_REQUEST,
  SET_APPOINTMENT_STATUS,
  GET_DOCTOR_SLOTS,
  CREATE_DOCTOR_SLOT,
  UPDATE_DOCTOR_SLOT,
  DELETE_DOCTOR_SLOT,
  GET_DOCTOR_SLOTS_PER_MONTH,
  DECLINE_PATIENT,
  GET_PATIENT_FUR_APPOINTMENTS,
  FETCH_PRESCRIPTIONS,
} from './apiUrl';
import { request } from './request';
import { getAPIToken } from './apiToken';
import { Patient } from '../types';

type ResponseType = AxiosResponse | any;
export interface RequestData {
  url: string | null;
  method: string | null;
  body: object | null;
}

export const loginUser = async (data: { email: string; password: string }) => {
  const stayLoggedIn = false;

  const requestOptions = {
    url: LOGIN_USER,
    method: 'POST',
    body: { stayLoggedIn, ...data },
  };

  let response;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const forgotPassword = async (data: { email: string }) => {
  const requestOptions = {
    url: FORGOT_PASSWORD,
    method: 'POST',
    body: { ...data },
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const resetPassword = async (data: { token: string; password: string }) => {
  const requestOptions = {
    url: RESET_PASSWORD,
    method: 'POST',
    body: { ...data },
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const getPatients = async (doctorId: number) => {
  const requestOptions = {
    url: FETCH_PATIENTS + doctorId,
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const getPatient = async (patientId: number) => {
  const requestOptions = {
    url: GET_PATIENT + patientId,
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const updatePatient = async (patientId: number, data: any) => {
  const requestOptions = {
    url: GET_PATIENT_BY_ID(patientId),
    method: 'PUT',
    token: `Bearer ${getAPIToken()}`,
    body: { ...data },
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const getPatientFurAppointments = async (patientId: number) => {
  const requestOptions = {
    url: GET_PATIENT_FUR_APPOINTMENTS(patientId),
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const getDoctorsComments = async (patientId: number) => {
  const requestOptions = {
    url: GET_DOCTOR_COMMENTS(patientId),
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const getAppointments = async (future = false) => {
  const requestOptions = {
    url: GET_APPOINTMENTS + future,
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const getDocuments = async (patientId: number) => {
  const requestOptions = {
    url: GET_PATIENT_DOCUMENTS(patientId),
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const getFile = async (fileId: number) => {
  const requestOptions = {
    url: GET_DOCUMENT_FILED + fileId,
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const getDoctorTasks = async () => {
  const requestOptions = {
    url: GET_DOCTOR_TASKS,
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const getDoctorTaskRequests = async () => {
  const requestOptions = {
    url: GET_DOCTOR_TASK_REQUEST,
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const getDoctorTaskAppointments = async () => {
  const requestOptions = {
    url: GET_DOCTOR_TASK_APPOINTMENTS,
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const getDoctorData = async (doctorId: number | undefined) => {
  const requestOptions = {
    url: GET_DOCTOR_DATA,
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const getPreparation = async () => {
  const requestOptions = {
    url: GET_PREPARATION,
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const setMedication = async (data: any) => {
  const requestOptions = {
    url: FETCH_MEDICATION,
    method: 'POST',
    token: `Bearer ${getAPIToken()}`,
    body: { ...data },
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const availableFollowupPrescription = async (patientId: number, data: any) => {
  const requestOptions = {
    url: AVAILABLE_FOLLOW_UP_PRESCRIPTION(patientId),
    method: 'PUT',
    token: `Bearer ${getAPIToken()}`,
    body: data,
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const followupPrescriptionRequest = async (requestId: number, data: any) => {
  const requestOptions = {
    url: FOLLOW_UP_PRESCRIPTION_REQUEST(requestId),
    method: 'PUT',
    token: `Bearer ${getAPIToken()}`,
    body: { ...data },
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const setAppointmentStatus = async (appointmentId: number, data: any) => {
  const requestOptions = {
    url: SET_APPOINTMENT_STATUS + appointmentId,
    method: 'PUT',
    token: `Bearer ${getAPIToken()}`,
    body: { ...data },
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const getSlots = async () => {
  const requestOptions = {
    url: GET_DOCTOR_SLOTS,
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const getDoctorSlotsPerMonth = async (startDate: string) => {
  const requestOptions = {
    url: GET_DOCTOR_SLOTS_PER_MONTH(startDate),
    method: 'GET',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const createSlot = async (data: any) => {
  const requestOptions = {
    url: CREATE_DOCTOR_SLOT,
    method: 'POST',
    token: `Bearer ${getAPIToken()}`,
    body: data,
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const updateSlot = async (slotId: string, data: any) => {
  const requestOptions = {
    url: UPDATE_DOCTOR_SLOT + slotId,
    method: 'PUT',
    token: `Bearer ${getAPIToken()}`,
    body: { ...data },
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const deleteSlot = async (slotId: string) => {
  const requestOptions = {
    url: DELETE_DOCTOR_SLOT + slotId,
    method: 'DELETE',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const declinePatientCall = async (patientId: number) => {
  const requestOptions = {
    url: DECLINE_PATIENT(patientId),
    method: 'PUT',
    token: `Bearer ${getAPIToken()}`,
    body: {},
  };

  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }

  return response;
};

export const fetchPrescriptionsListCall = async (token: string) => {
  const requestOptions = {
    url: FETCH_PRESCRIPTIONS,
    method: 'GET',
    token: `Bearer ${token}`,
    body: {},
  };
  let response: ResponseType;
  try {
    response = await request(requestOptions);
  } catch (error) {
    if (error) {
      response = error;
    }
  }
  return response;
};
