/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import type { PreparationQuestion as PreparationQuestionType } from '../../../types';
import style from './AppointmentItems.module.scss';
import { patientSliceSelector } from '../../../features/patientOverview/patientOverviewSlice';

const cx = classNames.bind(style);

type PropsTypes = { request: any };

export const ReadOnlyRequestForm = ({ request }: PropsTypes) => {
  const pageClasses = cx({ Recipe: true });
  const TextBold = cx('TextBold');
  const TextLabel = cx('TextLabel');
  const ColumnContainer = cx('ColumnContainer');
  const { patientInfo } = useSelector(patientSliceSelector);

  const [preparationQuestion, setPreparationQuestion] = useState<PreparationQuestionType[] | undefined>();

  const timeOfIngestionMapping = [
    { id: 1, value: 'Morgens' },
    { id: 2, value: 'Mittags' },
    { id: 3, value: 'Nachmittags' },
    { id: 4, value: 'Abends' },
    { id: 5, value: 'Zur Nacht' },
  ];

  const usedMapping = [
    { id: undefined, value: '' },
    { id: 1, value: 'Jeden Tag' },
    { id: 2, value: 'Regelmäßig (4-6 Tage die Woche)' },
    { id: 3, value: 'Gelegentlich (1–3 Tage pro Woche)' },
    { id: 4, value: 'Nur vereinzelt bei Beschwerden' },
  ];

  const trafficMapping = [
    { id: undefined, value: '' },
    { id: 1, value: 'Ja' },
    { id: 2, value: 'Nein' },
    { id: 3, value: 'Ich fahre kein Auto' },
  ];

  useEffect(() => {
    if (request) {
      if (request.furQuestion?.preparationQuestion.length > 0) {
        setPreparationQuestion(request.furQuestion?.preparationQuestion);
      }
    }
  }, [request]);

  const generalSideEffectsLabels = (item: string, other: string | undefined) => {
    const germanText = item
      .replace('reactions', 'Allergische Reaktionen / Unverträglichkeit')
      .replace('dryMouth', 'Trockene Schleimhäute (trockener Mund / gerötete Augen)')
      .replace('appetite', 'Gesteigerter Appetit (als unangenehmer Effekt)')
      .replace('nausea', 'Übelkeit')
      .replace('increasedFatigue', 'Erhöhte Müdigkeit (als unangenehmer Effekt)')
      .replace('cardioVascularProblems', 'Herzkreislaufprobleme (Blutdruckabfall, Herzrasen, Schwindel, Unwohlsein)')
      .replace('motorLimitations', 'Motorische Einschränkungen');

    const array = germanText.split(',');
    const newArray = array.filter((element) => element !== 'sonstiges');
    if (other) {
      newArray.push(other);
    }

    const finalText = newArray.join(', ');

    return finalText;
  };

  const psychologicalSideEffectsLabels = (item: string, other: string | undefined) => {
    const germanText = item
      .replace('alteredPerception', 'Veränderte Wahrnehmung / Bewusstseinsveränderung')
      .replace('thoughtDisorders', 'Denkstörungen / Gedächtnisstörungen')
      .replace('anxiety', 'Angst und Paniksituationen')
      .replace('hallucinations', 'Halluzinationen / Psychosen')
      .replace('toleranceDevelopment', 'Starke Toleranzentwicklung');
    const array = germanText.split(',');
    const newArray = array.filter((element) => element !== 'sonstiges');
    if (other) {
      newArray.push(other);
    }
    const finalText = array.join(', ');

    return finalText;
  };

  // READY
  const renderPreparationQuestions = () =>
    preparationQuestion &&
    preparationQuestion.map((element: PreparationQuestionType, index: number) => {
      const medicine = patientInfo.medication.find(
        (med: PreparationQuestionType) => med.id === request.furQuestion?.medicationId
      );
      const used = usedMapping.find((use) => use.id === element.used);
      const timeIngestion: (string | undefined)[] = [];

      if (element.timeOfIngestion) {
        element.timeOfIngestion.map((el) => {
          const timeValue = timeOfIngestionMapping.find((tim) => tim.id === el);
          const value = timeValue?.value;

          return timeIngestion.push(value);
        });
      }

      return (
        <div key={element.id}>
          <h2 style={{ marginTop: '10px' }}>{medicine.preparationListItem[index].name}</h2>
          <div className={style.MedicineContainer}>
            <p className={TextLabel}>Maximale Menge pro Tag</p>
            <p className={TextBold}>{`${element.dailyDosageMax} ${medicine.preparationListItem[index].dosageUnit}`}</p>
          </div>
          <div className={style.MedicineContainer}>
            <p className={TextLabel}>Maximale Einzeldosis</p>
            <p className={TextBold}>{`${element.singleDosageMax} ${medicine.preparationListItem[index].dosageUnit}`}</p>
          </div>
          <div className={style.MedicineContainer}>
            <p className={TextLabel}>Zeitpunkt der Einnahme</p>
            <p className={TextBold}>{`${timeIngestion.join(', ')}`}</p>
          </div>
          <div className={style.MedicineContainer}>
            <p className={TextLabel}>Häufigkeit der Einnahme</p>
            <p className={TextBold}>{`${used?.value}`}</p>
          </div>
        </div>
      );
    });

  // READY
  const renderExchangePreparationsItems = () => {
    const filteredPreparationQuestion = request.furQuestion?.preparationQuestion?.filter(
      (item: PreparationQuestionType) => item.changeThisPreparation
    );

    const foundMedication = patientInfo?.medication?.find(
      (item: any) => request.furQuestion?.medicationId === item?.id
    );

    const itemsToRENDER = filteredPreparationQuestion?.map((item: PreparationQuestionType) => {
      const prepID = item?.preparationListItemId;
      const foundPrep = foundMedication?.preparationListItem?.find((prep: any) => prep.id === prepID);
      return (
        <p
          className={TextBold}
        >{`Für ${foundPrep?.name} ${foundPrep?.cultivar}  wünscht sich der Patient ${item?.exchangeWithThisPreparation}`}</p>
      );
    });

    return itemsToRENDER;
  };

  return (
    <div className={pageClasses}>
      <div className={style.MainContainer}>
        <div className={ColumnContainer}>
          {request ? (
            <>
              <div className={style.Container}>
                <div className={style.MedicineContainer}>
                  <p className={TextLabel}>Antrag eingereicht am</p>
                  <p className={TextBold}>
                    {request &&
                      new Date(request.requestDate).toLocaleDateString('de-DE', {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    {' Uhr'}
                  </p>
                </div>
                <div>
                  <div className={style.MedicineContainer} style={{ marginTop: '20px' }}>
                    <p className={TextLabel}>Allgemeine Nebenwirkungen</p>
                    <p className={TextBold}>
                      {request.furQuestion?.generalSideEffects &&
                        generalSideEffectsLabels(
                          request.furQuestion?.generalSideEffects,
                          request.furQuestion?.generalSideEffectsOther
                        )}
                    </p>
                  </div>
                  <div className={style.MedicineContainer}>
                    <p className={TextLabel}>Psychische Nebenwirkungen</p>
                    <p className={TextBold}>
                      {request.furQuestion?.psychologicalSideEffects &&
                        psychologicalSideEffectsLabels(
                          request.furQuestion?.psychologicalSideEffects,
                          request.furQuestion?.psychologicalSideEffectsOther
                        )}
                    </p>
                  </div>
                  <div className={style.MedicineContainer}>
                    <p className={TextLabel}>Sicher in der Anwendung?</p>
                    <p className={TextBold}>
                      {request.furQuestion?.isSafeHandling === true && 'Ja'}
                      {request.furQuestion?.isSafeHandling === false && 'Nein'}
                    </p>
                  </div>
                  <div className={style.MedicineContainer}>
                    <p className={TextLabel}>Fähig am Straßenverkehr teilzunehmen?</p>
                    <p className={TextBold}>
                      {trafficMapping.find((traf) => traf.id === request.furQuestion?.roadTraffic)?.value}
                    </p>
                  </div>
                </div>
              </div>
              <div className={style.WhiteBoxContainer}>
                <div className={style.MedicineContainer}>
                  <p className={style.BlackHeadings}>Neue Medikamente</p>
                  <p className={TextBold}>{request.furQuestion?.newMedicines || 'Nein'}</p>
                </div>
                <div className={style.MedicineContainer}>
                  <p className={style.BlackHeadings}>Medizinische Neuigkeiten</p>
                  <p className={TextBold}>{request.furQuestion?.medicalNews || 'Nein'}</p>
                </div>
                <div className={style.MedicineContainer}>
                  <p className={TextLabel}>Apotheke</p>
                  <p className={TextBold}>{patientInfo?.patientPharmacyRelation?.name}</p>
                </div>
                <div>
                  <h2 className={style.BlackHeadings}>Austauschpräparate</h2>
                  {request.furQuestion?.medicationAvailable === 1 && (
                    <p className={TextBold}>Lieferbarkeit ist überprüft, keine Austauschpräparate gewünscht.</p>
                  )}
                  {request.furQuestion?.medicationAvailable === 2 && (
                    <p className={TextBold}>
                      Lieferbarkeit ist <b>nicht</b> überprüft, keine Austauschpräparate gewünscht.
                    </p>
                  )}
                  {request.furQuestion?.medicationAvailable === 'changeMedication' && renderExchangePreparationsItems()}
                </div>
                <div style={{ marginTop: '10px' }}>
                  <h2 className={style.BlackHeadings}>Kommentar des Arztes</h2>
                  <p className={TextBold}>{request?.doctorMessage}</p>
                </div>
              </div>
            </>
          ) : (
            <div className={style.Container}>
              <p className={TextLabel}>Es liegt kein Antrag vor.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
