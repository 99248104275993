/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import React, { useContext, useState } from 'react';
import classNames from 'classnames/bind';
import { Chevron } from '../Icon/Icons/Chevron';
import style from './CollapsibleCard.module.scss';

const cx = classNames.bind(style);

interface CollapsibleCardProps {
  isMainOrSubDoctor?: boolean;
  children: JSX.Element | JSX.Element[] | string | boolean;
  folgerezept?: boolean;
  open?: boolean;
}

interface CollapseContext {
  isExpanded?: boolean;
  toggleExpanded?: () => void;
  closeAccordion?: () => void;
}

const MainContainer = cx({ CollapsibleCardMainContainer: true });
const HeaderContainer = cx({ CollapsibleCardHeaderContainer: true });
const HeaderDataContainer = cx({ CollapsibleCardHeaderDataContainer: true });
const HoverIconContainer = cx({ CollapsibleCardHoverIconContainer: true });
const HoverIconRotated = cx({ CollapsibleCardHoverIconRotated: true });
const HoverIcon = cx({ CollapsibleCardHoverIcon: true });
const ExpandContainer = cx({ CollapsibleCardExpandContainer: true });

const CollapsibleCardContext = React.createContext<CollapseContext>({});

const useCollapse = () => {
  const collapseContext = useContext(CollapsibleCardContext);

  return collapseContext;
};

const CollapsibleCardHeader = ({ children, open = true }: CollapsibleCardProps) => {
  const { isExpanded, toggleExpanded } = useCollapse();

  return (
    <div className={HeaderContainer}>
      <div className={HeaderDataContainer}>{children}</div>
      {open && (
        <div onClick={toggleExpanded} className={HoverIconContainer}>
          <div className={isExpanded ? HoverIconRotated : HoverIcon}>
            <Chevron size={25} />
          </div>
        </div>
      )}
    </div>
  );
};

const CollapsibleCardBody = ({ children }: CollapsibleCardProps) => {
  const { isExpanded } = useCollapse();

  return <>{isExpanded ? <div className={ExpandContainer}>{children}</div> : null}</>;
};

const CollapsibleCard = ({ isMainOrSubDoctor = true, children, folgerezept }: CollapsibleCardProps) => {
  const [isExpanded, setExpanded] = useState<boolean>();

  const toggleExpanded = () => setExpanded(!isExpanded);
  const closeAccordion = () => setExpanded(false);

  return (
    <CollapsibleCardContext.Provider value={{ isExpanded, toggleExpanded, closeAccordion }}>
      <div
        style={
          isMainOrSubDoctor && !folgerezept ? {} : { borderColor: '#f56e6e', borderWidth: 2, borderStyle: 'solid' }
        }
        className={MainContainer}
      >
        {children}
      </div>
    </CollapsibleCardContext.Provider>
  );
};

CollapsibleCard.Header = CollapsibleCardHeader;
CollapsibleCard.Body = CollapsibleCardBody;
CollapsibleCard.useCollapse = useCollapse;

export { CollapsibleCard };
