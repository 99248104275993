/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react';
import { Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { PatientsHeader } from './PatientsHeader';
import style from './Patients.module.scss';
import { Patient } from '../../types';
import { Button, Loader } from '../../components/atoms';
import { Input } from '../../components/molecules';
import { fetchPatients, patientsSliceSelector } from './patientListSlice';

const cx = classNames.bind(style);

export function Patients(): JSX.Element {
  const [patientsList, setPatientsList] = useState<Patient[]>([]);
  const [numberOfActivePatients, setNumberOfActivePatients] = useState(0);
  const [loading, setLoading] = useState(false);
  const [letter, setLetter] = useState('');
  const alphabet = 'abcdefghijklmnopqrstuvwxyz';

  const today = new Date();
  const dispatch = useDispatch();
  const { patients } = useSelector(patientsSliceSelector);
  const intl = useIntl();
  const navigate = useNavigate();

  const showPatientinfoComponent = (patientId: any, page: string) => {
    navigate(`/patients/${page}/${patientId}`);
  };

  useEffect(() => {
    if (!patients.length) {
      setLoading(true);
      dispatch(fetchPatients());
      setLoading(false);
    }

    setPatientsList(patients);
    setNumberOfActivePatients(patients.filter((p: { active: any }) => p.active).length);
  }, [patients]);

  useEffect(() => {
    setPatientsList(patients.filter((x: { lastname: string }) => x.lastname.toLowerCase().startsWith(letter)));
  }, [letter]);

  const search = (input: string) => {
    const isNum = /^\d+$/.test(input);

    if (isNum) {
      setPatientsList(patients.filter((item: { id: number }) => String(item.id).includes(input)));
      return;
    }

    setPatientsList(
      patients.filter(
        (item: { lastname: string; firstname: string }) =>
          item.lastname.toLowerCase().includes(input) || item.firstname.toLowerCase().includes(input)
      )
    );
  };

  function formatDate(dateString: string) {
    const date = new Date(dateString);

    return [
      date.getDate().toString().padStart(2, '0'),
      (date.getMonth() + 1).toString().padStart(2, '0'),
      date.getFullYear(),
    ].join('.');
  }

  const pageClasses = cx('container');
  const MainDiv = cx({ PatientsMainDiv: true });
  const TableContainerClasses = cx({ PatientsTableContainer: true });
  const AlphabetButton = cx({ PatientsAlphabetButton: true });
  const InputClasses = cx({ PatientsInput: true });

  const renderAlphabetButtons = () =>
    alphabet.split('').map((c, i) => (
      <Button
        key={i}
        className={AlphabetButton}
        onClick={() => (letter === c ? setLetter('') : setLetter(c))}
        type={letter === c ? 'secondary' : 'primary'}
      >
        {c}
      </Button>
    ));

  const renderPatientList = () =>
    patientsList.map((row: any, index: number) => (
      <>
        {(index === 0 || row.lastname[0].toLowerCase() !== patientsList[index - 1].lastname[0].toLowerCase()) && (
          <TableRow className={style.PatientsNoBorder}>
            <TableCell className={style.PatientsTableCellHeaderMain}>{row.lastname[0].toUpperCase()}</TableCell>
            <TableCell className={style.PatientsTableCellHeader}>STATUS</TableCell>
            <TableCell className={style.PatientsTableCellHeader}>TRACKING</TableCell>
            <TableCell className={style.PatientsTableCellHeader}>
              <FormattedMessage id='patients.LastAppointment' />
            </TableCell>
          </TableRow>
        )}
        <TableRow
          hover
          key={index}
          className={style.PatientsPointer}
          onClick={() => showPatientinfoComponent(row.id, 'Overview')}
        >
          <TableCell className={style.PatientsTableCell}>
            <div className={style.PatientsFlexContainer}>
              <div className={style.PatientsId}>ID: {row.id}</div>
              <div className={style.PatientsName}>
                {row.lastname}, {row.firstname}
              </div>
              <div className={style.PatientsBirthday}>{formatDate(row.birthday)}</div>
            </div>
          </TableCell>
          {row.active ? (
            <TableCell className={style.PatientsTableCellGreen}>aktiv</TableCell>
          ) : (
            <TableCell className={style.PatientsTableCellRed}>inaktiv</TableCell>
          )}
          {row.isPatientTracking ? (
            <TableCell className={style.PatientsTableCellGreen}>aktiv</TableCell>
          ) : (
            <TableCell className={style.PatientsTableCellRed}>inaktiv</TableCell>
          )}
          {row.lastAppointment ? (
            <TableCell className={style.PatientsTableCell}>
              vor &nbsp;
              {Math.ceil((today.getTime() - Date.parse(row.lastAppointment as string)) / (1000 * 3600 * 24))}d
            </TableCell>
          ) : (
            <TableCell className={style.PatientsTableCell}>-</TableCell>
          )}
        </TableRow>
      </>
    ));

  return (
    <div className='details_container'>
      <div className={pageClasses}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} className={MainDiv}>
            {loading ? (
              <Loader />
            ) : (
              <>
                <PatientsHeader activePatientsNumber={numberOfActivePatients} patientsNumber={patients.length} />
                <Grid container>
                  <Grid item xs={12} sm={6} md={8} lg={9} xl={7}>
                    {renderAlphabetButtons()}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} lg={2}>
                    <Input
                      type='search'
                      placeholder={intl.formatMessage({ id: 'patients.SearchPatients' })}
                      inputMode='search'
                      onChange={(e) => search(e.target.value.toLowerCase())}
                      icon='magnify'
                      className={InputClasses}
                    />
                  </Grid>
                </Grid>
                <TableContainer className={TableContainerClasses}>
                  <Table size='medium'>
                    <TableBody>{renderPatientList()}</TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
