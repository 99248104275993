/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable import/prefer-default-export */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import type { Medication as MedicationType, PreparationListItem as PreparationListItemType } from '../../types';
import { Loader } from '../../components/atoms';
import { PatientNavigation } from '../../components/organisms/PatientNavigation/PatientNavigation';
import { Appointments } from './appointments/Appointments';
import { FollowRecipe } from './followRecipe/FollowRecipe';
import { Medication } from './medication/Medication';
import { Record } from './record/Record';
import Tracking from './tracking/Tracking';
import style from './Patient.module.scss';
import {
  globalSliceSelector,
  setShowNavigationDialog,
  setRenderPage,
  setNextNavigation,
  setHasSavedtask,
} from '../../app/appSlice';
import {
  fetchDoctorsComments,
  fetchDocuments,
  fetchPatientInfo,
  patientSliceSelector,
  setDoctorsComments,
  setDocuments,
  setFurAppointments,
  setPatientInfo,
} from './patientOverviewSlice';
import { Overview } from './overview/Overview';
import { Dialog } from '../../components/atoms/Dialog/Dialog';

const cx = classNames.bind(style);
const pageClasses = cx('container', { Patient: true });

export function Patient(props: any): JSX.Element {
  const navigate = useNavigate();
  const { patientId, page } = useParams();
  const [currentPage, setCurrentPage] = useState(page);
  const [mainDiseaseLabels, setMainDiseaseLabels] = useState('');
  const [currentMedication, setCurrentMedication] = useState<PreparationListItemType[]>([]);
  const [prevMedication, setPrevMedication] = useState<MedicationType[]>([]);
  const [isAppointmentUpdated, setIsAppointmentUpdated] = useState(false);

  const { patientInfo, doctorsComments, documents, loadPatientInfo } = useSelector(patientSliceSelector);
  const { isMainOrSubDoctor, showNavigationDialog, hasSavedTask, nextNavigation } = useSelector(globalSliceSelector);
  const dispatch = useDispatch();

  const convertLabels = (d: string) => {
    if (!d) {
      return;
    }
    const newString = d
      .replace('chronicPains', 'Chronische Schmerzen')
      .replace('sleepDisorders', 'Schlafstörungen')
      .replace('migraine', 'Migräne')
      .replace('adhs', 'AD(H)S')
      .replace('depression', 'Depression')
      .replace('otherComplaints', 'Andere Beschwerden');
    setMainDiseaseLabels(newString);
  };

  const getCurrentMedication = (medication: MedicationType[]) => {
    const foundMedication = medication.filter(
      (med: MedicationType) => med.start !== undefined && (med.end === undefined || med.end === '')
    );

    const newMed = foundMedication[0]?.preparationListItem;
    if (newMed) {
      setCurrentMedication(newMed);
    }
  };

  const getPreviousMedication = (medication: MedicationType[]) => {
    const filteredMedication = medication.filter(
      (med: MedicationType) => med.start !== undefined && med.end !== undefined && med.end !== ''
    );

    if (!filteredMedication || filteredMedication.length === 0) {
      return;
    }

    const sortedMedication = filteredMedication.sort((a, b) => Date.parse(b.start) - Date.parse(a.start));
    setPrevMedication(sortedMedication);
  };

  const getPatient = () => {
    if (patientInfo && Object.keys(patientInfo).length !== 0) {
      if (patientInfo.firstPresentation?.mainDisease) {
        convertLabels(patientInfo.firstPresentation.mainDisease);
      } else if (patientInfo.questionnaire?.length > 0) {
        setMainDiseaseLabels(patientInfo.questionnaire[0].mainDisease);
      }

      if (patientInfo.medication && patientInfo.medication.length !== 0) {
        getPreviousMedication(patientInfo.medication);
        getCurrentMedication(patientInfo.medication);
      }
    }
  };

  useEffect(() => {
    const payload: any = { patientId };
    if (Object.keys(patientInfo).length === 0 || isAppointmentUpdated) {
      // @ts-ignore
      dispatch(fetchPatientInfo(payload));
    }
    if (Object.keys(patientInfo).length !== 0) {
      getPatient();
    }
    if (!doctorsComments.length || isAppointmentUpdated) {
      // @ts-ignore
      dispatch(fetchDoctorsComments(payload));
    }
    if (!documents.length || isAppointmentUpdated) {
      // @ts-ignore
      dispatch(fetchDocuments(payload));
    }
    setIsAppointmentUpdated(false);
  }, [isAppointmentUpdated, patientId]);

  useEffect(() => {
    getPatient();
  }, [patientInfo]);

  const showMedication = () => currentMedication.length !== 0 || prevMedication.length !== 0;

  const onBackButtonClick = () => {
    if (!isMainOrSubDoctor && hasSavedTask) {
      dispatch(setShowNavigationDialog(true));
    } else {
      clearState();
      navigate(-1);
    }
  };

  const confirmNavigation = () => {
    clearState();
    dispatch(setShowNavigationDialog(false));
    if (nextNavigation !== '') {
      dispatch(setRenderPage(nextNavigation));
    }
  };

  const cancelNavigation = () => {
    dispatch(setShowNavigationDialog(false));
    dispatch(setNextNavigation(''));
  };

  const clearState = () => {
    dispatch(setHasSavedtask(false));
    dispatch(setPatientInfo({}));
    dispatch(setFurAppointments([]));
    dispatch(setDoctorsComments([]));
    dispatch(setDocuments([]));
  };

  const renderSwitch = (param: string) => {
    switch (param) {
      case 'Overview':
        return (
          <Overview
            currentMedication={currentMedication}
            patientInfo={patientInfo}
            showMedication={showMedication}
            mainDiseaseLabels={mainDiseaseLabels}
            prevMedication={prevMedication}
          />
        );
      case 'Record':
        return <Record mainDiseaseLabels={mainDiseaseLabels} documents={documents} doctorsComments={doctorsComments} />;
      case 'Medication':
        return <Medication prevMedication={prevMedication} patientInfo={patientInfo} />;
      case 'Appointments':
        return (
          <Appointments
            patientAppointments={patientInfo?.appointment}
            patient={patientInfo}
            setIsAppointmentUpdated={setIsAppointmentUpdated}
            isAppointmentUpdated={isAppointmentUpdated}
            isMainOrSubDoctor={isMainOrSubDoctor}
            // @ts-ignore
            setCurrentPage={setCurrentPage}
          />
        );
      case 'Tracking':
        return <Tracking />;
      case 'FollowRecipe':
        return <FollowRecipe currentMedication={currentMedication} getPatient={getPatient} patientInfo={patientInfo} />;
      default:
        return (
          <Overview
            currentMedication={currentMedication}
            patientInfo={patientInfo}
            showMedication={showMedication}
            mainDiseaseLabels={mainDiseaseLabels}
            prevMedication={prevMedication}
          />
        );
    }
  };

  return loadPatientInfo ? (
    <Loader />
  ) : (
    <div className='details_container'>
      <div className={pageClasses}>
        <div className={style.PatientOverview}>
          {showNavigationDialog && (
            <Dialog
              messageHead='Alles erledigt?'
              message={
                'Diese/r Patient/in ist aktuell nicht mehr bei Ihnen in Behandlung. Drücken Sie auf “Ja”, können sie keine weitere Einstellungen der Medikationen oder Folgerezepte erledigen. Möchten Sie das Patientenprofil verlassen?'
              }
              type={'negativeDialog'}
              spinner={'primary'}
              buttonText='Ja'
              onConfirm={() => confirmNavigation()}
              onCancel={() => cancelNavigation()}
              disabled={false}
            />
          )}
          <Icon name='arrow left' size='big' style={{ marginLeft: 10 }} onClick={() => onBackButtonClick()} />
          <PatientNavigation
            // @ts-ignore
            currentPage={currentPage}
            // @ts-ignore
            setCurrentPage={setCurrentPage}
          />
          {
            // @ts-ignore
            renderSwitch(currentPage)
          }
        </div>
      </div>
    </div>
  );
}
