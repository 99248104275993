/* eslint-disable import/prefer-default-export */
import { Line } from 'react-chartjs-2';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import type { LifeQuality as LifeQualityTypes } from '../../../types';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function LineChart({ props }): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const newLegendClickHandler = (e, legendItem, legend) => {
    const index = legendItem.datasetIndex;
    const ci = legend.chart;

    if (ci.isDatasetVisible(index)) {
      e.chart.data.datasets[index].backgroundColor = '#fff';
      e.chart.setDatasetVisibility(index, false);
    } else {
      e.chart.data.datasets[index].backgroundColor = e.chart.data.datasets[index].borderColor;
      e.chart.setDatasetVisibility(index, true);
    }
    e.chart.update();
  };

  const options = {
    responsive: true,
    layout: {
      padding: {
        top: 45,
        bottom: 5,
        left: 30,
        right: 15,
      },
    },
    elements: {
      point: {
        radius: props?.length === 1 ? 3 : 0,
      },
    },
    plugins: {
      legend: {
        onClick: newLegendClickHandler,
        position: 'right' as const,
        align: 'start' as const,
        labels: {
          padding: 15,
          boxWidth: 15,
          boxHeight: 15,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: true,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: true,
          tickLength: 10,
        },
        ticks: {
          showLabelBackdrop: true,
          padding: 10,
        },
      },
      y: {
        max: 10,
        min: 0,
        grid: {
          drawBorder: false,
        },
        ticks: {
          display: true,
        },
      },
    },
  };

  const labels = props?.map((e: LifeQualityTypes) =>
    new Date(e.queryDate)
      .toLocaleString('de-DE', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      })
      .replace('-', '.')
  );

  const data = {
    labels,
    datasets: [
      {
        label: 'Stimmung',
        data: props?.map((e: LifeQualityTypes) => e.mood),
        borderColor: 'rgb(0, 146, 69)',
        backgroundColor: 'rgb(0, 146, 69)',
      },
      {
        label: 'Lebensfreude',
        data: props?.map((e: LifeQualityTypes) => e.joyOfLife),
        borderColor: 'rgb(41, 171, 226)',
        backgroundColor: 'rgb(41, 171, 226)',
      },
      {
        label: 'Appetit',
        data: props?.map((e: LifeQualityTypes) => e.appetite),
        borderColor: 'rgb(212, 20, 90)',
        backgroundColor: 'rgb(212, 20, 90)',
      },
      {
        label: 'Sexualität',
        data: props?.map((e: LifeQualityTypes) => e.sexuality),
        borderColor: 'rgb(241, 90, 36)',
        backgroundColor: 'rgb(241, 90, 36)',
      },
      {
        label: 'Beruf / Alltag',
        data: props?.map((e: LifeQualityTypes) => e.everydayWork),
        borderColor: 'rgb(6, 49, 146)',
        backgroundColor: 'rgb(6, 49, 146)',
      },
      {
        label: 'Konzetration',
        data: props?.map((e: LifeQualityTypes) => e.concentration),
        borderColor: 'rgb(117, 76, 36)',
        backgroundColor: 'rgb(117, 76, 36)',
      },
      {
        label: 'Schlaf',
        data: props?.map((e: LifeQualityTypes) => e.sleep),
        borderColor: 'rgb(255, 0, 255)',
        backgroundColor: 'rgb(255, 0, 255)',
      },
      {
        label: 'Aktivität/Belastbartkeit',
        data: props?.map((e: LifeQualityTypes) => e.generalActivity),
        borderColor: 'rgb(255, 239, 14)',
        backgroundColor: 'rgb(255, 239, 14)',
      },
    ],
  };

  return (
    <div>
      <Line height={130} options={options} data={data} />
    </div>
  );
}
