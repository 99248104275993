/* eslint-disable import/prefer-default-export */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import classNames from 'classnames/bind';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import style from './App.module.scss';
import { AuthGuard, GuestGuard } from './routes/WrappedRoutes';
import { Login } from './features/auth/LoginPage';
import { Patients } from './features/patientList/Patients';
import { Profile } from './features/profile/Profile';
import { Dashboard } from './features/dashboard/Dashboard';
import { Calendar } from './features/calendar/Calendar';
import { Patient } from './features/patientOverview/Patient';
import SideBarComponent from './features/SideBarComponent';
import { ResetPassword } from './features/auth/passwordReset/ResetPassword';
import { globalSliceSelector } from './app/appSlice';
import { authSliceSelector } from './features/auth/authSlice';
import messages from './translation/messages.json';
import PrescriptionsComponent from './features/prescriptions/prescriptions.component';
import { Appointment } from './features/appointment/Appointment';

const cx = classNames.bind(style);

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) ?? React.Fragment;

export function App() {
  const appClasses = cx({ App: true });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { language } = useSelector(globalSliceSelector);
  const { user } = useSelector(authSliceSelector);
  const userId = user?.ninoxId;

  useEffect(() => {
    const { hostname } = window.location;
    let releaseStage = 'production';

    if (hostname === 'localhost') {
      releaseStage = 'development';
    } else if (hostname === 'doctor.claire.nowomed.com') {
      releaseStage = 'staging';
    }

    Bugsnag.start({
      apiKey: '12a0987b049016057c5dd02bc3ba8863',
      releaseStage,
      plugins: [new BugsnagPluginReact()],
    });
    BugsnagPerformance.start({ apiKey: '12a0987b049016057c5dd02bc3ba8863' });

    if (userId) {
      Bugsnag.setUser(userId);
    }
  }, [userId]);

  return (
    <ErrorBoundary>
      <ToastContainer autoClose={5000} position={'top-right'} />
      <div className={appClasses}>
        <IntlProvider locale='de' messages={(messages as any).de} defaultLocale='de'>
          <BrowserRouter>
            <SideBarComponent />
            <Routes>
              <Route
                path='/'
                element={
                  <GuestGuard>
                    <Dashboard />
                  </GuestGuard>
                }
              />
              <Route
                path='/dashboard'
                element={
                  <AuthGuard>
                    <Dashboard />
                  </AuthGuard>
                }
              />
              <Route
                path='/patients'
                element={
                  <AuthGuard>
                    <Patients />
                  </AuthGuard>
                }
              />
              <Route
                path='/profile'
                element={
                  <AuthGuard>
                    <Profile />
                  </AuthGuard>
                }
              />
              <Route
                path='/calendar'
                element={
                  <AuthGuard>
                    <Calendar />
                  </AuthGuard>
                }
              />
              <Route
                path='/prescriptions'
                element={
                  <AuthGuard>
                    <PrescriptionsComponent />
                  </AuthGuard>
                }
              />
              <Route
                path='/login'
                element={
                  <GuestGuard>
                    <Login />
                  </GuestGuard>
                }
              />
              <Route
                path='/patients/:page/:patientId'
                element={
                  <AuthGuard>
                    <Patient />
                  </AuthGuard>
                }
              />
              <Route
                path='/appointment/:appointmentId'
                element={
                  <AuthGuard>
                    <Appointment />
                  </AuthGuard>
                }
              />
              <Route path='*' element={<Navigate to='/login' />} />
              <Route path='reset-password' element={<ResetPassword />} />
            </Routes>
          </BrowserRouter>
        </IntlProvider>
      </div>
    </ErrorBoundary>
  );
}
