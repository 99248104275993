/* eslint-disable import/prefer-default-export */
import React from 'react';
import classNames from 'classnames/bind';
import { InputLoader } from '../InputLoader/InputLoader';
import styles from './Button.module.scss';

const cx = classNames.bind(styles);

type ButtonProps = {
  spinner?: 'none' | 'primary' | 'secondary' | 'red-primary';
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  type?: 'primary' | 'secondary';
  style?: React.CSSProperties;
};

export function Button({
  spinner = 'none',
  children,
  type = 'primary',
  disabled = false,
  style,
  ...props
}: ButtonProps): JSX.Element {
  const ButtonClasses = cx(
    {
      Button: true,
      [`Button-${type}`]: true,
      'Button-disabled': disabled, // Add extra styling for disabled mode
    },
    props.className
  );

  return (
    <button type='button' className={ButtonClasses} onClick={props.onClick} disabled={disabled} style={style}>
      {spinner !== 'none' && disabled === true ? <InputLoader spinner={spinner} /> : <span>{children}</span>}
    </button>
  );
}
